import { useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';
import { nanoid } from 'nanoid';
import { useGate } from 'statsig-react';

import {
  DEFAULT_GROUP_NAME,
  DEFAULT_RESPONSE_COUNT,
  ERROR_SAVE_DRAFT,
  RESPONSE_COUNT_INCREMENT,
  validateInstantSurveyPrompts,
} from '@api/instant_survey';

import { GATES } from '@constants';
import { ActionArea, Button, EditableTitle, OverlayTriggerTooltip, toast } from '@utilities';
import { PlusIcon } from '@utilities/icons';

import InstantSurveyToolbar from '../InstantSurveyToolbar';
import ModalDuplicateGroup from './components/ModalDuplicateGroup';
import ModalMissingRequiredPrompts from '../../../CreateSurvey/components/Panelists/components/ModalMissingRequiredPrompts';
import ModalValidationErrors from './components/ModalValidationErrors';
import QuotaGroupCard from './components/QuotaGroupCard';

import { getCurrentQuestionnairePrice, getCurrentSamplePrice } from '../../utilities/helpers';
import { SurveyContext } from '../../utilities/context';

import styles from './_index.module.scss';

const Panelists = ({ onNext }) => {
  const { insightsDocumentType, instantSurveyType, saveInstantSurvey, survey, updateSurvey } =
    useContext(SurveyContext);

  const [groupToDuplicate, setGroupToDuplicate] = useState(null);
  const [isValidationModalOpen, setIsValidationModalOpen] = useState(false);
  const [isValidatingPanelists, setIsValidatingPanelists] = useState(false);
  const [validationErrors, setValidationErrors] = useState(null);

  const { value: canMultiQuota } = useGate(GATES.MULTI_QUOTA);

  const activeGroups = survey.groups.filter(({ _delete }) => !_delete);
  const currentQuestionnairePrice = useMemo(
    () =>
      getCurrentQuestionnairePrice({
        questionnairePricing: insightsDocumentType.questionnairePricing,
        survey,
      }),
    []
  );
  const currentSamplePrice = useMemo(
    () =>
      getCurrentSamplePrice({
        samplePricing: insightsDocumentType.samplePricing,
        survey,
      }),
    [survey]
  );
  const currentTotalResponses = activeGroups.reduce((acc, group) => acc + group.quotaLimit, 0);
  const minimumResponsesRequired = Math.min(
    ...insightsDocumentType.samplePricing
      .filter(({ quotaGroups }) => quotaGroups === activeGroups.length)
      .map(({ responses }) => responses)
  );

  const canAddQuotaGroup = insightsDocumentType.samplePricing.find(
    ({ quotaGroups }) => quotaGroups === activeGroups.length + 1
  );
  const canDecrementResponses = insightsDocumentType.samplePricing.some(
    ({ responses }) => responses === currentTotalResponses - RESPONSE_COUNT_INCREMENT
  );
  const canIncrementResponses =
    currentTotalResponses <
    Math.max(
      ...insightsDocumentType.samplePricing
        .filter(({ quotaGroups }) => quotaGroups === activeGroups.length)
        .map(({ responses }) => responses)
    );
  const creditsTotal = currentSamplePrice?.amount + currentQuestionnairePrice?.amount;
  const groupsMissingRequiredPrompts = activeGroups
    .map(({ id, name, promptAnswers }) => ({
      id,
      name,
      prompts: instantSurveyType.prompts.filter((prompt) => {
        if (!prompt.isRequired) return false;

        const answers = promptAnswers.filter(
          ({ _delete, promptId }) => !_delete && prompt.id === promptId
        );
        return answers.length === 0 || answers.some(({ values }) => values.length === 0);
      }),
    }))
    .filter(({ prompts }) => prompts.length > 0);
  const remainingAvailableResponses =
    Math.max(
      ...insightsDocumentType.samplePricing
        .filter(({ quotaGroups }) => quotaGroups === activeGroups.length + 1)
        .map(({ responses }) => responses)
    ) - currentTotalResponses;

  const addQuotaGroupTooltip =
    canAddQuotaGroup && canIncrementResponses
      ? 'Add Quota Group'
      : !canAddQuotaGroup
      ? 'Maximum amount of quota groups reached'
      : 'Maximum amount of responses reached: you cannot create another quota group';
  const isContinueDisabled =
    currentTotalResponses < minimumResponsesRequired ||
    activeGroups.some(({ name }) => !name.trim()) ||
    new Set(activeGroups.map(({ name }) => name.trim())).size !== activeGroups.length ||
    !survey.title.trim();
  const navigate = useNavigate();

  const onAddQuotaGroup = () => {
    updateSurvey({
      groups: [
        ...survey.groups,
        {
          id: nanoid(),
          name: `${DEFAULT_GROUP_NAME} (${activeGroups.length + 1})`,
          promptAnswers: [],
          quotaLimit: Math.min(DEFAULT_RESPONSE_COUNT, remainingAvailableResponses),
        },
      ],
    });
  };

  const onContinue = async () => {
    if (groupsMissingRequiredPrompts.length > 0) return setIsValidationModalOpen(true);
    setIsValidatingPanelists(true);
    let response;

    try {
      response = await saveInstantSurvey();
      updateSurvey(response);
    } catch (error) {
      setIsValidatingPanelists(false);
      toast(ERROR_SAVE_DRAFT, { status: 'error' });
      return;
    }

    try {
      await validateInstantSurveyPrompts({ id: response.id });
      setIsValidatingPanelists(false);
    } catch (error) {
      setValidationErrors(error?.response?.data?.error || []);
      setIsValidatingPanelists(false);
      return;
    }

    onNext();
  };

  const onDelete = (groupId) => {
    if (typeof groupId === 'string') {
      return updateSurvey({ groups: survey.groups.filter(({ id }) => id !== groupId) });
    }
    onUpdateGroup(groupId, { _delete: true });
  };

  const onDuplicateGroup = ({ name, promptAnswers, quotaLimit }) => {
    setGroupToDuplicate({
      id: nanoid(), // strip id from group
      name,
      promptAnswers: cloneDeep(promptAnswers),
      quotaLimit,
    });
  };

  const onUpdateGroup = (groupId, propsToUpdate) => {
    const groupIndex = survey.groups.findIndex(({ id }) => id === groupId);
    let updatedGroups = [...survey.groups];
    if (propsToUpdate.answers) propsToUpdate.validationStatus = null;
    updatedGroups[groupIndex] = Object.assign(updatedGroups[groupIndex], propsToUpdate);
    updateSurvey({ groups: updatedGroups });
  };

  return (
    <div className={styles['panelists']}>
      <ActionArea
        buttons={[
          <Button
            data-testid="cancel-button"
            key="cancel-button"
            onClick={() => navigate(-1)}
            text="Cancel"
            variant="secondary"
          />,
          <Button
            data-testid="continue-button"
            isDisabled={isContinueDisabled}
            isLoading={isValidatingPanelists}
            key="continue-button"
            onClick={onContinue}
            text="Continue"
          />,
        ]}
        className={styles['panelists-layout']}
      >
        <InstantSurveyToolbar
          isSaveAsDraftDisabled={isContinueDisabled}
          tools={
            canMultiQuota
              ? [
                  <OverlayTriggerTooltip content={addQuotaGroupTooltip}>
                    <button
                      disabled={!canAddQuotaGroup || !canIncrementResponses}
                      onClick={onAddQuotaGroup}
                    >
                      <PlusIcon />
                    </button>
                  </OverlayTriggerTooltip>,
                ]
              : []
          }
        >
          <EditableTitle onChange={(title) => updateSurvey({ title })} value={survey.title} />
        </InstantSurveyToolbar>

        <div className={styles['panelists-pricing']}>
          <p>
            <strong>{activeGroups.length}</strong> Quota Group
            {activeGroups.length > 1 ? 's ' : ' '}
            with <strong>{currentTotalResponses}</strong> Total Responses
          </p>
          <p>
            Cost:&nbsp;
            {currentSamplePrice && currentQuestionnairePrice ? (
              <strong>{`${creditsTotal} Credit${creditsTotal === 1 ? '' : 's'}`}</strong>
            ) : (
              <span>N/A</span>
            )}
          </p>
        </div>
        {currentTotalResponses < minimumResponsesRequired && (
          <div className={styles['panelists-pricing-error']}>
            An Instant Survey must have a minimum of {minimumResponsesRequired} responses.
          </div>
        )}
        <ul className={styles['panelists-cards']}>
          {activeGroups.map((group) => (
            <li key={group.id}>
              <QuotaGroupCard
                canDecrementResponses={canDecrementResponses}
                canDelete={activeGroups.length > 1}
                canDuplicate={canAddQuotaGroup && canIncrementResponses}
                canIncrementResponses={canIncrementResponses}
                group={group}
                onDelete={() => onDelete(group.id)}
                onDuplicate={() => onDuplicateGroup(group)}
                onUpdate={(propsToUpdate) => onUpdateGroup(group.id, propsToUpdate)}
                survey={survey}
                surveyType={instantSurveyType}
              />
            </li>
          ))}
        </ul>
        {canMultiQuota && (
          <div className={styles['panelists-add-quota-group']}>
            <OverlayTriggerTooltip content={addQuotaGroupTooltip} position="bottom">
              <button
                data-testid="add-quota-group-button"
                disabled={!canAddQuotaGroup || !canIncrementResponses}
                onClick={onAddQuotaGroup}
              >
                <PlusIcon />
              </button>
            </OverlayTriggerTooltip>
          </div>
        )}
      </ActionArea>

      {groupToDuplicate && (
        <ModalDuplicateGroup
          group={groupToDuplicate}
          groups={activeGroups}
          onClose={() => setGroupToDuplicate(null)}
          onSubmit={(group) => {
            updateSurvey({ groups: [...survey.groups, group] });
            setGroupToDuplicate(null);
          }}
          remainingAvailableResponses={remainingAvailableResponses}
        />
      )}
      {isValidationModalOpen && (
        <ModalMissingRequiredPrompts
          groups={activeGroups}
          invalidGroups={groupsMissingRequiredPrompts}
          onClose={() => setIsValidationModalOpen(false)}
        />
      )}
      {validationErrors && (
        <ModalValidationErrors
          onClose={() => setValidationErrors(null)}
          survey={survey}
          validationErrors={validationErrors}
        />
      )}
    </div>
  );
};

Panelists.propTypes = {
  onNext: PropTypes.func.isRequired,
};

export default Panelists;
