import { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useGate } from 'statsig-react';

import { QUESTION_TYPES } from '@api/instant_survey';
import { readEssayResponses, readSurveyResponses } from '@api/survey_dashboard';
import { GATES } from '@constants';
import {
  Button,
  Checkbox,
  DEFAULT_CHART_COLORS,
  Error,
  FilterGroup,
  Layout,
  Legend,
  Loader,
  LoaderSkeleton,
  ResetFilterIcon,
  Select,
  truncateTextWithEllipsis,
} from '@utilities';

import ComparisonCharts from './components/ComparisonCharts';
import OpenEnded from './components/OpenEnded';

import { DISPLAY_AS_OPTIONS } from './utilities/helpers';

import styles from './_index.module.scss';

const Responses = ({
  globalFilters,
  globalFiltersError,
  isFiltersLoading,
  questionFilters,
  questionFiltersError,
  survey,
}) => {
  const compareByOptions = useMemo(
    () => [
      {
        label: 'Primary Segments',
        options: [
          { label: 'Overall', value: '' },
          ...(survey.groups?.length === 1 ? [] : [{ label: 'Quota Group', value: 'quota_group' }]),
        ],
      },
      {
        label: 'Demographic Segments',
        options: globalFilters?.demographicFilters
          ?.filter(({ compareBy }) => compareBy)
          .map(({ id, label }) => ({
            label,
            value: id,
          })),
      },
    ],
    [globalFilters, survey]
  );
  const filterableGroups = useMemo(
    () => survey.groups?.filter(({ quotaGroupRef }) => Boolean(quotaGroupRef)),
    []
  );

  const [compareBy, setCompareBy] = useState(
    survey.groups?.length > 1 ? compareByOptions[0].options[1] : compareByOptions[0].options[0]
  );
  const [displayAs, setDisplayAs] = useState(DISPLAY_AS_OPTIONS[0]);
  const [essayResponses, setEssayResponses] = useState();
  const [surveyResponses, setSurveyResponses] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [loadingError, setLoadingError] = useState();
  const [selectedFilters, setSelectedFilters] = useState({});
  const [selectedGroups, setSelectedGroups] = useState(
    filterableGroups?.reduce((acc, group) => ({ ...acc, [group.quotaGroupRef]: true }), {}) || {}
  );
  const [selectedQuestionFilters, setSelectedQuestionFilters] = useState({});
  const { survey_id: surveyId } = useParams();

  const requestController = useRef(null);
  const { value: canQuestionFilter } = useGate(GATES.SURVEY_DASHBOARD_QUESTION_FILTERS);

  useEffect(() => {
    if (!isFiltersLoading && !globalFiltersError) {
      getData();
    }
    return () => requestController?.current?.abort();
  }, [compareBy.value, isFiltersLoading, selectedFilters, selectedGroups, selectedQuestionFilters]);

  const areAllGroupsSelected =
    survey.groups?.length < 2 || Object.keys(selectedGroups).every((key) => selectedGroups[key]);
  const areAnyFiltersSelected = useMemo(() => {
    return (
      Object.keys(selectedFilters).some((key) =>
        Object.keys(selectedFilters[key]).some((optionKey) => selectedFilters[key][optionKey])
      ) ||
      Object.keys(selectedQuestionFilters).some((key) =>
        Object.keys(selectedQuestionFilters[key]).some(
          (optionKey) => selectedQuestionFilters[key][optionKey]
        )
      )
    );
  }, [selectedFilters, selectedQuestionFilters]);
  const compareByGroups = useMemo(() => {
    switch (compareBy.value) {
      case '':
        return [{ color: DEFAULT_CHART_COLORS[0], id: 'overall', name: 'Overall' }];
      case 'quota_group':
        return survey.groups?.map(({ id, name }, index) => ({
          color: DEFAULT_CHART_COLORS[index % DEFAULT_CHART_COLORS.length],
          id,
          name,
        }));
      default:
        return (
          globalFilters?.demographicFilters
            ?.find(({ id }) => id === compareBy.value)
            ?.compareBy.map(({ id, label }, index) => ({
              color: DEFAULT_CHART_COLORS[index % DEFAULT_CHART_COLORS.length],
              id,
              name: label,
            })) || []
        );
    }
  }, [compareBy]);

  const getData = async () => {
    const controller = new AbortController();
    requestController?.current?.abort();
    requestController.current = controller;

    const query = {
      compare_by: compareBy?.value,
      option_filter: Object.keys(selectedQuestionFilters)
        .reduce((acc, key) => {
          Object.keys(selectedQuestionFilters[key]).forEach((optionKey) => {
            if (selectedQuestionFilters[key][optionKey]) acc.push(optionKey);
          });

          return acc;
        }, [])
        .join(';'),
      quota_group_filter: areAllGroupsSelected
        ? ''
        : Object.keys(selectedGroups)
            .filter((key) => selectedGroups[key])
            .join(';'),
      ...Object.keys(selectedFilters).reduce((acc, key) => {
        return {
          ...acc,
          [key]: Object.keys(selectedFilters[key])
            .filter((optionKey) => selectedFilters[key][optionKey])
            .join(';'),
        };
      }, {}),
    };

    setIsLoading(true);
    setLoadingError(null);
    try {
      const requests = [
        readEssayResponses({
          query,
          signal: controller.signal,
          surveyId,
        }),
        readSurveyResponses({
          query,
          signal: controller.signal,
          surveyId,
        }),
      ];
      const responses = await Promise.all(requests);
      setEssayResponses(responses[0]);
      setSurveyResponses(responses[1]);
      setIsLoading(false);
    } catch (error) {
      if (!controller.signal.aborted) {
        setLoadingError(error);
      }
    }
  };

  const handleResetFilters = () => {
    setSelectedFilters({});
    setSelectedQuestionFilters({});
  };

  if (globalFiltersError) return <Error status={globalFiltersError?.response?.status} />;
  if (isFiltersLoading) return <Loader isCentered />;

  return (
    <Layout.Flex className={styles['responses']} gap="large">
      <Layout.Sidebar className={styles['responses-sidebar']}>
        <Layout.Flex.Column gap="x-large">
          <div className={styles['responses-compare-by']}>
            <Select
              className={styles['responses-filter-select']}
              label="Compare By"
              onChange={setCompareBy}
              options={compareByOptions}
              value={compareBy}
            />
            <Legend items={compareByGroups} />
          </div>

          <Select
            className={styles['responses-filter-select']}
            label="Display Data In"
            onChange={setDisplayAs}
            options={DISPLAY_AS_OPTIONS}
            value={displayAs}
          />

          {globalFilters?.demographicFilters && (
            <Layout.Flex.Column gap="small">
              <h4>Demographic Filters</h4>
              {globalFilters.demographicFilters.map((filter) => (
                <FilterGroup
                  filter={filter}
                  key={filter.id}
                  onChange={(value) =>
                    setSelectedFilters({ ...selectedFilters, [filter.id]: value })
                  }
                  value={selectedFilters[filter.id]}
                />
              ))}
            </Layout.Flex.Column>
          )}

          {filterableGroups?.length > 1 && (
            <Layout.Flex.Column gap="small">
              <h4>Quota Groups</h4>
              {filterableGroups.map((group) => (
                <Checkbox
                  id={`group-filter-${group.quotaGroupRef}`}
                  isChecked={selectedGroups[group.quotaGroupRef]}
                  key={group.quotaGroupRef}
                  label={truncateTextWithEllipsis({ length: 20, text: group.name })}
                  onChange={() =>
                    setSelectedGroups({
                      ...selectedGroups,
                      [group.quotaGroupRef]: !selectedGroups[group.quotaGroupRef],
                    })
                  }
                  title={group.name}
                  value={group.quotaGroupRef}
                />
              ))}
            </Layout.Flex.Column>
          )}

          {canQuestionFilter && (
            <Layout.Flex.Column gap="small">
              <h4>Question Filters</h4>
              {questionFiltersError && (
                <p className={styles['responses-filter-error']}>
                  Question option filters currently unavailable. We're looking into it!
                </p>
              )}
              {questionFilters?.map((filter) => (
                <FilterGroup
                  filter={filter}
                  key={filter.id}
                  onChange={(value) =>
                    setSelectedQuestionFilters({
                      ...selectedQuestionFilters,
                      [filter.id]: value,
                    })
                  }
                  value={selectedQuestionFilters[filter.id]}
                />
              ))}
            </Layout.Flex.Column>
          )}

          <Button
            className={styles['responses-filter-reset']}
            icon={<ResetFilterIcon />}
            isDisabled={!areAnyFiltersSelected}
            onClick={handleResetFilters}
            text="Reset Filters"
            variant="secondary"
          />
        </Layout.Flex.Column>
      </Layout.Sidebar>

      <Layout.Fill as={Layout.Flex.Column} className={styles['responses-main']} gap="x-large">
        {loadingError && <Error status={loadingError?.response?.status} />}
        {!loadingError &&
          survey?.questions.map((question, index) => (
            <Fragment key={question.id}>
              <div className={styles['responses-question']}>
                <h3 className={styles['responses-question-text']}>
                  Q{index + 1}. {question.text}
                </h3>
                {isLoading && (
                  <LoaderSkeleton height={500}>
                    <rect x="0" y="10" rx="2" ry="2" width="250" height="30" />
                    <rect x="0" y="50" rx="2" ry="2" width="1000" height="450" />
                  </LoaderSkeleton>
                )}
                {!isLoading && (
                  <>
                    {question.type !== QUESTION_TYPES.ESSAY.value && (
                      <ComparisonCharts
                        data={surveyResponses.find(
                          ({ id, shortName }) =>
                            [id, shortName].includes(question.id) ||
                            id === question?.questionSourceId
                        )}
                        displayAs={displayAs.value}
                        question={question}
                      />
                    )}
                    {question.type === QUESTION_TYPES.ESSAY.value && (
                      <OpenEnded
                        data={essayResponses.find(
                          ({ id, shortName }) =>
                            [id, shortName].includes(question.id) ||
                            id === question?.questionSourceId
                        )}
                      />
                    )}
                  </>
                )}
              </div>
              {index !== survey.questions.length - 1 && <hr />}
            </Fragment>
          ))}
      </Layout.Fill>
    </Layout.Flex>
  );
};

Responses.propTypes = {
  globalFilters: PropTypes.object,
  globalFiltersError: PropTypes.object,
  isFiltersLoading: PropTypes.bool,
  questionFilters: PropTypes.array,
  questionFiltersError: PropTypes.object,
  survey: PropTypes.object.isRequired,
};

export default Responses;
