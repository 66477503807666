import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import LogRocket from 'logrocket';
import PropTypes from 'prop-types';

import { ERROR_SAVE_DRAFT } from '@api/instant_survey';
import { createAtlasSurvey } from '@api/surveys';
import { HelpIcon, OverlayTriggerTooltip, SaveIcon, toast, Toolbar } from '@utilities';

import ModalSaveAsDraft from '../../../CreateSurvey/components/ModalSaveAsDraft';
import { SurveyContext } from '../../utilities/context';

const InstantSurveyToolbar = ({ children, isSaveAsDraftDisabled, tools = [] }) => {
  const { insightsDocument, instantSurveyType, saveInstantSurvey, survey } =
    useContext(SurveyContext);
  const [isSaveAsDraftOpen, setIsSaveAsDraftOpen] = useState(false);
  const navigate = useNavigate();

  const handleSaveAndExit = async () => {
    try {
      const response = await saveInstantSurvey();
      await createAtlasSurvey({
        atlasInstantSurveyRef: response.id,
        id: insightsDocument?.id,
        status: 'draft',
        title: survey.title,
      });
    } catch (error) {
      toast(ERROR_SAVE_DRAFT, { status: 'error' });
      return;
    }

    LogRocket.track('InstantSurvey_SaveAsDraft', { type: survey.instantSurveyType });
    navigate({
      pathname: '/dashboard/workspace/surveys',
      search: new URLSearchParams({ filters: 'is_draft_item' }).toString(),
    });
  };

  return (
    <Toolbar
      tools={[
        ...tools,
        <OverlayTriggerTooltip content="Save Draft">
          <button
            data-testid="save-draft-button"
            disabled={isSaveAsDraftDisabled}
            onClick={() => setIsSaveAsDraftOpen(true)}
          >
            <SaveIcon />
          </button>
        </OverlayTriggerTooltip>,
        ...(instantSurveyType.helpUrl
          ? [
              <OverlayTriggerTooltip aria-label="Help" content="Help">
                <a
                  data-testid="help-link-button"
                  href={instantSurveyType.helpUrl}
                  rel="noreferrer"
                  target="_blank"
                >
                  <HelpIcon />
                </a>
              </OverlayTriggerTooltip>,
            ]
          : []),
      ]}
    >
      <>
        {children}
        {isSaveAsDraftOpen && (
          <ModalSaveAsDraft
            isActive
            onClose={() => setIsSaveAsDraftOpen(false)}
            onSubmit={handleSaveAndExit}
          />
        )}
      </>
    </Toolbar>
  );
};

InstantSurveyToolbar.propTypes = {
  children: PropTypes.node,
  isSaveAsDraftDisabled: PropTypes.bool,
  tools: PropTypes.array,
};

export default InstantSurveyToolbar;
