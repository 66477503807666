import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { useGate } from 'statsig-react';

import {
  readExternalQuestions,
  readInstantSurvey,
  readInstantSurveyType,
} from '@api/instant_survey';
import { readGlobalFilters, readRawData } from '@api/survey_dashboard';
import { readSurvey, readSurveyType, surveyStatus } from '@api/surveys';
import { GATES } from '@constants';

import {
  Announcement,
  Breadcrumbs,
  Error,
  getFormattedDate,
  LoaderSkeleton,
  Tabs,
} from '@utilities';

import Details from './components/Details';
import InsightsDetails from './components/InsightsDetails';
import RawData from './components/RawData';
import Responses from './components/Responses';

const Survey = () => {
  const [globalFilters, setGlobalFilters] = useState();
  const [globalFiltersError, setGlobalFiltersError] = useState();
  const [insightsDocument, setInsightsDocument] = useState();
  const [insightsType, setInsightsType] = useState();
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [isFiltersLoading, setIsFiltersLoading] = useState(true);
  const [isRawDataLoading, setIsRawDataLoading] = useState(true);
  const [loadingError, setLoadingError] = useState(false);
  const [questionFilters, setQuestionFilters] = useState();
  const [questionFiltersError, setQuestionFiltersError] = useState();
  const [rawData, setRawData] = useState();
  const [rawDataLoadingError, setRawDataLoadingError] = useState();
  const [survey, setSurvey] = useState();
  const [surveyType, setSurveyType] = useState();

  const { value: canQuestionFilter } = useGate(GATES.SURVEY_DASHBOARD_QUESTION_FILTERS);
  const { value: canSurveyDashboard, isLoading: isStatsigLoading } = useGate(
    GATES.SURVEY_DASHBOARD
  );

  const dataRequestController = useRef();
  const filtersRequestController = useRef();
  const rawDataRequestController = useRef();

  const navigate = useNavigate();
  const { routes } = useSelector((state) => state?.user?.data);
  const instantSurveyRoutes = routes?.find(({ id }) => id === 'instant-survey-reports')?.routes;

  let { survey_id: surveyId, tab_id: tabId } = useParams();

  useEffect(() => {
    if (!isStatsigLoading) {
      if (!canSurveyDashboard) {
        return navigate('/dashboard/workspace/surveys', { replace: true });
      }
      if (!tabId) {
        navigate(`/dashboard/survey/${surveyId}/${instantSurveyRoutes[0].id}`, { replace: true });
      }
      getData();
    }
    return dataRequestController?.current?.abort();
  }, [isStatsigLoading, surveyId]);

  useEffect(() => {
    if (insightsDocument?.id) {
      getFilters();
      getRawData();
    }
    return () => {
      filtersRequestController?.current?.abort();
      rawDataRequestController?.current?.abort();
    };
  }, [insightsDocument]);

  const getData = async () => {
    const controller = new AbortController();
    const signal = controller.signal;

    try {
      const insightsSurvey = await readSurvey({ id: surveyId, signal });
      setInsightsDocument(insightsSurvey);
      if (insightsSurvey.atlasInstantSurveyRef) {
        const atlasRequests = [
          readInstantSurvey({
            id: insightsSurvey.atlasInstantSurveyRef,
            params: { include_response_count: true },
            signal,
          }),
          readInstantSurveyType({
            id: insightsSurvey.instantSurveyTypeId,
            signal,
          }),
        ];
        const [instantSurveyResponse, instantSurveyTypeResponse] = await Promise.all(atlasRequests);
        const { groups, ...instantSurvey } = instantSurveyResponse;
        setSurvey({
          ...instantSurvey,
          // sorting groups here so it never impacts a draft survey
          groups: groups.sort((a, b) => a.quotaGroupRef - b.quotaGroupRef),
        });
        setSurveyType(instantSurveyTypeResponse);
      } else {
        setInsightsType(
          await readSurveyType({
            documentId: insightsSurvey.instantSurveyTypeId,
            signal,
          })
        );
      }
      setIsDataLoading(false);
    } catch (error) {
      if (!controller.signal.aborted) {
        setLoadingError(error);
      }
    }
  };

  const getFilters = async () => {
    const controller = new AbortController();
    filtersRequestController.current = controller;

    setIsFiltersLoading(true);
    try {
      setGlobalFilters(
        await readGlobalFilters({
          signal: controller.signal,
          surveyId: insightsDocument?.id,
        })
      );

      if (canQuestionFilter) {
        try {
          setQuestionFilters(
            await readExternalQuestions({
              signal: controller.signal,
              surveySourceId: insightsDocument?.surveySourceId,
            })
          );
        } catch (error) {
          if (!controller.signal.aborted) {
            setQuestionFiltersError(error);
          }
        }
      }

      setIsFiltersLoading(false);
    } catch (error) {
      if (!controller.signal.aborted) {
        setGlobalFiltersError(error);
      }
    }

    setIsFiltersLoading(false);
  };

  const getRawData = async () => {
    const controller = new AbortController();
    rawDataRequestController.current = controller;
    setIsRawDataLoading(true);

    try {
      const response = await readRawData({ signal: controller.signal, surveyId });
      setRawData(response);
      setIsRawDataLoading(false);
    } catch (error) {
      if (!controller.signal.aborted) {
        setRawDataLoadingError(error);
      }
    }
  };

  return (
    <div>
      <Breadcrumbs />
      {loadingError && <Error status={loadingError?.response?.status} />}
      {!loadingError && isDataLoading && (
        <LoaderSkeleton height={725}>
          <rect x="0" y="0" rx="4" ry="4" width="850" height="95" />
          <rect x="0" y="105" rx="4" ry="4" width="1333" height="40" />
          <rect x="0" y="155" rx="4" ry="4" width="300" height="30" />
          <rect x="0" y="195" rx="4" ry="4" width="1333" height="165" />
          <rect x="0" y="380" rx="4" ry="4" width="300" height="30" />
          <rect x="0" y="420" rx="4" ry="4" width="1333" height="58" />
          <rect x="0" y="488" rx="4" ry="4" width="1333" height="58" />
          <rect x="0" y="556" rx="4" ry="4" width="1333" height="58" />
        </LoaderSkeleton>
      )}

      {!loadingError && !isDataLoading && (
        <>
          <div>
            <h2>{survey?.title || insightsDocument.title}</h2>
            <div>
              {insightsDocument.status === surveyStatus.DRAFT && (
                <Announcement
                  text="You are currently viewing a draft which does not have reporting available."
                  variant="warn"
                />
              )}
              {survey && (
                <>
                  <p>
                    Survey fielded on{' '}
                    <strong>
                      {survey.issueDate ? getFormattedDate(new Date(survey.issueDate)) : 'N/A'}
                    </strong>
                  </p>
                  {survey.groups.length > 1 && (
                    <p>
                      <strong>{survey.groups.length}</strong> quota groups
                    </p>
                  )}
                  <p>
                    <strong>
                      {survey.groups.reduce((acc, { quotaLimit }) => acc + quotaLimit, 0)}
                    </strong>{' '}
                    total responses
                  </p>
                  <p>
                    <strong>{survey.questions.length}</strong>{' '}
                    {survey.questions.length === 1 ? 'question' : 'questions'}
                  </p>
                </>
              )}
              {!survey && (
                <>
                  <p>
                    Survey created on{' '}
                    <strong>
                      {insightsDocument.created_datetime
                        ? getFormattedDate(new Date(insightsDocument.created_datetime))
                        : 'N/A'}
                    </strong>
                  </p>
                  <p>
                    <strong>
                      {insightsDocument.groups.reduce(
                        (acc, { responseCount }) => acc + responseCount,
                        0
                      )}
                    </strong>{' '}
                    responses
                  </p>
                  <p>
                    <strong>{insightsDocument.questions.length}</strong>{' '}
                    {insightsDocument.questions.length === 1 ? 'question' : 'questions'}
                  </p>
                </>
              )}
            </div>
            <hr />
          </div>

          <Tabs
            routes={[
              { label: 'Survey Details', id: 'details' },
              { label: 'Survey Responses', id: 'responses' },
              { label: 'Raw Data', id: 'raw-data' },
            ].map(({ id, label }) => ({ label, to: `/dashboard/survey/${surveyId}/${id}` }))}
          />
          {tabId === 'details' && survey && <Details survey={survey} surveyType={surveyType} />}
          {tabId === 'details' && !survey && (
            <InsightsDetails survey={insightsDocument} surveyType={insightsType} />
          )}
          {tabId === 'responses' && (
            <Responses
              globalFilters={globalFilters}
              globalFiltersError={globalFiltersError}
              isFiltersLoading={isFiltersLoading}
              questionFilters={questionFilters}
              questionFiltersError={questionFiltersError}
              survey={survey || insightsDocument}
            />
          )}
          {tabId === 'raw-data' && (
            <RawData
              isRawDataLoading={isRawDataLoading}
              rawData={rawData}
              rawDataLoadingError={rawDataLoadingError}
            />
          )}
        </>
      )}
    </div>
  );
};

export default Survey;
