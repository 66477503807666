import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import {
  readInstantSurvey,
  SURVEY_GROUP_VALIDATION_ERROR_HEADER,
  SURVEY_GROUP_VALIDATION_ERROR_TEXT,
  SURVEY_GROUP_VALIDATION_STATUS,
  validateInstantSurvey,
} from '@api/instant_survey';
import { publishAtlasSurvey } from '@api/surveys';
import {
  Accordion,
  Announcement,
  Button,
  Card,
  EditableTitle,
  Error,
  formatData,
  LoaderView,
  Tag,
} from '@utilities';
import {
  CheckCircleSolidIcon,
  ExclamationTriangleIcon,
  FlowArrowIcon,
  TargetIcon,
} from '@utilities/icons';

import Cart from '../../../Checkout/components/Cart';
import GroupAdvancedPrompts from '../Panelists/components/QuotaGroupCard/components/GroupAdvancedPrompts';
import GroupMadlib from '../Panelists/components/QuotaGroupCard/components/GroupMadlib';
import InstantSurveyToolbar from '../InstantSurveyToolbar';
import { payWithCredits } from '../../../Checkout/helpers/constants';

import { getCurrentQuestionnairePrice, getCurrentSamplePrice } from '../../utilities/helpers';
import { SurveyContext } from '../../utilities/context';

import styles from '../../../CreateSurvey/components/Review/_index.module.scss';

const Review = ({ onPublish, setActiveStep }) => {
  const {
    insightsDocument,
    insightsDocumentType,
    instantSurveyType,
    saveInstantSurvey,
    survey,
    updateSurvey,
  } = useContext(SurveyContext);
  const { firstName, lastName, surveyInfo } = useSelector((state) => state?.user?.data);

  // create a copy of userSurveyCreditsRemaining so it doesn't change visually
  // when the onPublish call updates the redux user credits remaining
  const [creditsRemainingAtReview] = useState(surveyInfo?.userCredits);
  const [error, setError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isValidating, setIsValidating] = useState(true);

  const controller = new AbortController();
  const signal = controller.signal;
  const timeoutRef = useRef(null);

  const { ERROR, NOT_ENOUGH_SAMPLE, NOT_VALIDATED, VALIDATING } = SURVEY_GROUP_VALIDATION_STATUS;

  const formatAvailablePanelists = (count) => {
    return `${formatData({ format: '0,0', value: Math.min(count, 3000) })}${
      count >= 3000 ? '+' : ''
    }`;
  };
  const questionnairePrice = useMemo(
    () =>
      getCurrentQuestionnairePrice({
        questionnairePricing: insightsDocumentType.questionnairePricing,
        survey,
      }),
    []
  );
  const samplePrice = useMemo(
    () => getCurrentSamplePrice({ samplePricing: insightsDocumentType.samplePricing, survey }),
    []
  );

  const totalResponsesRequested = survey.groups.reduce(
    (acc, { quotaLimit }) => acc + quotaLimit,
    0
  );

  const lowestPanelistRatio = Math.min(
    ...survey.groups.map(({ quotaLimit, validationUsers }) => (validationUsers || 0) / quotaLimit)
  );
  const expectedCompletionDays =
    lowestPanelistRatio >= 10 ? '1-2' : lowestPanelistRatio >= 5 ? '2-5' : '3-7';

  useEffect(() => {
    if (survey.groups.some(({ validationStatus }) => validationStatus === NOT_VALIDATED)) {
      getSurveyValidation();
    } else if (survey.groups.some(({ validationStatus }) => validationStatus === VALIDATING)) {
      pollSurveyValidation();
    } else {
      setIsValidating(false);
    }

    return () => {
      controller.abort();
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const getSurveyValidation = async () => {
    try {
      await validateInstantSurvey({ id: survey.id });
      timeoutRef.current = setTimeout(() => pollSurveyValidation(), 5000);
    } catch (error) {
      setError(error);
    }
  };

  const getGroupIcon = (group) => {
    switch (group.validationStatus) {
      case ERROR:
      case NOT_ENOUGH_SAMPLE:
        return <ExclamationTriangleIcon className={styles['review-group-icon-warning']} />;
      default:
        return <CheckCircleSolidIcon className={styles['review-group-icon']} />;
    }
  };

  const pollSurveyValidation = async () => {
    try {
      const surveyResponse = await readInstantSurvey({
        id: survey.id,
        signal,
      });
      if (
        surveyResponse.groups.some(({ validationStatus }) =>
          [NOT_VALIDATED, VALIDATING].includes(validationStatus)
        )
      ) {
        timeoutRef.current = setTimeout(() => pollSurveyValidation(), 5000);
      } else {
        updateSurvey(surveyResponse);
        setIsValidating(false);
      }
    } catch (error) {
      if (!signal.aborted) {
        console.error(error);
        setError(error);
      }
    }
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);

    try {
      await saveInstantSurvey({ signal });

      onPublish(
        await publishAtlasSurvey({
          atlasInstantSurveyRef: survey.id,
          id: insightsDocument?.id,
          status: null,
          title: survey.title,
        })
      );
    } catch (error) {
      console.error(error);
      setError(error);
      setIsSubmitting(false);
    }
  };

  if (error) {
    return <Error status={error?.response?.status} />;
  }

  if (isValidating) {
    return (
      <LoaderView heading="Validating Sample Size...">
        <p>
          Just making sure there are enough panelists available to complete this survey, as
          requested. Validation should only take a moment.
        </p>
      </LoaderView>
    );
  }

  return (
    <div className={styles['review']}>
      <div>
        <InstantSurveyToolbar isSaveAsDraftDisabled={!survey.title.trim()}>
          <EditableTitle onChange={(title) => updateSurvey({ title })} value={survey.title} />
        </InstantSurveyToolbar>
        <h2>You're almost ready to launch your survey</h2>
        <p>
          Our team will review your survey for quality control before it is set active for
          panelists. Typically this takes less than 24 business hours.
        </p>
      </div>

      <div className={styles['review-column-layout']}>
        <div className={styles['review-column']}>
          <Card className={styles['review-card']} padding="x-large">
            <div className={styles['review-section']}>
              <h3>{instantSurveyType.name}</h3>
              <p>
                Estimated Completion:&nbsp;
                <strong>{expectedCompletionDays} business days</strong>
              </p>
              <p>We will send an email notification when complete.</p>
            </div>
            <hr />
            <div className={styles['review-section']}>
              <h5>
                <strong>Panelists</strong> (<button onClick={() => setActiveStep(0)}>edit</button>)
              </h5>
              {survey.groups.length > 1 && (
                <p>
                  <strong>{survey.groups.length}</strong> Quota Groups with&nbsp;
                  <strong>{totalResponsesRequested}</strong> Total Responses
                </p>
              )}
              {survey.groups.some(
                ({ validationStatus }) => validationStatus === NOT_ENOUGH_SAMPLE
              ) && (
                <Announcement
                  className={styles['review-announcement']}
                  text={`Your survey may not collect ${totalResponsesRequested} total responses. You may modify your prompts to include more panelists.`}
                  variant="warn"
                />
              )}
              <div>
                {survey.groups.length === 1 && (
                  <>
                    <GroupMadlib
                      group={survey.groups[0]}
                      isReadOnly
                      surveyType={instantSurveyType}
                    />
                    {instantSurveyType.advancedPrompts.length > 0 && (
                      <GroupAdvancedPrompts
                        advancedPrompts={instantSurveyType.advancedPrompts}
                        group={survey.groups[0]}
                        isCompleted
                      />
                    )}
                  </>
                )}
                {survey.groups.length > 1 &&
                  survey.groups.map((group) => (
                    <Accordion icon={getGroupIcon(group)} key={group.id} label={group.name}>
                      <div className={styles['review-group']}>
                        <GroupMadlib group={group} isReadOnly surveyType={instantSurveyType} />
                        {instantSurveyType.advancedPrompts.length > 0 && (
                          <GroupAdvancedPrompts
                            advancedPrompts={instantSurveyType.advancedPrompts}
                            group={group}
                            isCompleted
                          />
                        )}
                        {group.validationStatus !== ERROR && (
                          <>
                            <div>
                              <h4>Available Panelists</h4>
                              <p>{formatAvailablePanelists(group.validationUsers)}</p>
                            </div>
                            <div>
                              <h4>Expected Responses</h4>
                              <p>
                                <strong>
                                  {formatData({
                                    format: '0,0',
                                    value: Math.min(group.expectedCompletes, group.quotaLimit),
                                  })}
                                </strong>
                              </p>
                            </div>
                          </>
                        )}
                        {group.validationStatus === ERROR && (
                          <Announcement
                            header={SURVEY_GROUP_VALIDATION_ERROR_HEADER}
                            text={SURVEY_GROUP_VALIDATION_ERROR_TEXT}
                            variant="warn"
                          />
                        )}
                      </div>
                    </Accordion>
                  ))}
              </div>
              {survey.groups.length === 1 && (
                <>
                  {survey.groups[0].validationStatus === ERROR ? (
                    <Announcement
                      className={styles['review-announcement']}
                      header={SURVEY_GROUP_VALIDATION_ERROR_HEADER}
                      text={SURVEY_GROUP_VALIDATION_ERROR_TEXT}
                      variant="warn"
                    />
                  ) : (
                    <>
                      <div>
                        <h4>Available Panelists</h4>
                        <p>{formatAvailablePanelists(survey.groups[0].validationUsers)}</p>
                      </div>
                      <div>
                        <h4>Expected Responses</h4>
                        <p>
                          <strong>
                            {formatData({
                              format: '0,0',
                              value: Math.min(
                                survey.groups[0].expectedCompletes,
                                survey.groups[0].quotaLimit
                              ),
                            })}
                          </strong>
                        </p>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>

            <div className={styles['review-section']}>
              <h5>
                <strong>Questionnaire</strong> (
                <button onClick={() => setActiveStep(1)}>edit</button>)
              </h5>
              <Accordion
                className={styles['review-questions']}
                isDefaultOpen
                label={`${survey.questions.length} Questions`}
              >
                <ol className={styles['review-question-list']}>
                  {survey.questions.map(({ id, text }) => (
                    <li key={id}>
                      <div>
                        {text}
                        {survey.questionLogic[id]?.skip && (
                          <Tag icon={<FlowArrowIcon />} tooltip="Skippable" />
                        )}
                        {survey.questionLogic[id]?.disqualify && (
                          <Tag icon={<TargetIcon />} tooltip="Disqualifier" />
                        )}
                      </div>
                    </li>
                  ))}
                </ol>
              </Accordion>
            </div>
          </Card>
        </div>

        <div className={styles['review-column']}>
          <Cart
            items={[
              {
                description: questionnairePrice?.description,
                name: 'Questionnaire Length',
                prices: {
                  credits: questionnairePrice?.amount,
                },
              },
              {
                description:
                  `${totalResponsesRequested} completes` +
                  (survey.groups.length > 1
                    ? `, ${survey.groups.length} quota group${survey.groups.length > 1 ? 's' : ''}`
                    : ''),
                name: 'Survey Sample',
                prices: {
                  credits: samplePrice?.amount,
                },
              },
            ]}
            paymentOption={payWithCredits}
          >
            <Card className={styles['review-credits-card']} padding="small">
              <h4>Credits for {`${firstName} ${lastName}`}</h4>
              <div>
                <p>Available</p>
                <span>{creditsRemainingAtReview.toLocaleString()} Credits</span>
              </div>
              {creditsRemainingAtReview < samplePrice?.amount + questionnairePrice?.amount && (
                <p className={styles['review-credits-warning']}>
                  Not enough credits to launch. Please contact your Numerator team for more
                  information on credits.
                </p>
              )}
            </Card>
          </Cart>

          <div className={styles['review-actions']}>
            <Button
              data-testid="previous-button"
              onClick={() => setActiveStep(1)}
              text="Previous"
              variant="secondary"
            />
            <Button
              data-testid="launch-button"
              isDisabled={
                !survey.title.trim() ||
                creditsRemainingAtReview < samplePrice?.amount + questionnairePrice?.amount
              }
              isLoading={isSubmitting}
              onClick={handleSubmit}
              text="Launch"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

Review.propTypes = {
  onPublish: PropTypes.func.isRequired,
  setActiveStep: PropTypes.func.isRequired,
};

export default Review;
